<template>
  <header class="text-gray-600 body-font bg-background-accent capitalize z-50 font-normal
                 shadow-lg relative">
    <div class="container mx-auto flex md:flex-wrap px-5 pt-1 md:flex-row items-center
                content-center pb-5 md:pb-0 flex-col justify-between">
      <router-link :to="{name: 'Home', hash: '#hero'}" class="cursor-pointer">
        <div class="relative h-20 flex flex-row content-center uppercase text-background-primary
                    text-3xl md:text-4xl font-extrabold md:gap-6 gap-2 mx-auto">
          <img src="@/assets/logo.svg" alt="Poselytics Logo"
               class="object-contain md:h-28 h-16"/>
          <div class="my-auto">
            <span>P</span><span class="text-background-light">o</span><span>selytics</span>
          </div>
        </div>
      </router-link>
      <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center flex-row gap-6"
           aria-label="">
        <span></span>
      </nav>
      <nav class="md:ml-auto flex flex-wrap items-center justify-center lowercase  text-xl
                text-background-primary px-3" aria-label="">
        <LanguagePicker class="mr-5"/>
        <router-link :to="{name: 'Home', hash: '#hero'}">
          <a class="cursor-pointer"
             :class="inView === 'hero' ? 'active-header-link header-link' : 'header-link'">
            {{ $t('header.hero') }}</a>
        </router-link>
        <span class="px-3">|</span>
        <router-link :to="{name: 'Home', hash: '#team'}"
                     :class="inView === 'team' ? 'active-header-link header-link' : 'header-link'">
          <a class="cursor-pointer">
            {{ $t('header.team') }}</a>
        </router-link>
      </nav>
      <a class="inline-flex items-center border-0 py-1 px-3 ml-5 btn-primary shadow-none
                focus:outline-none rounded text-base mt-0 hidden lg:inline"
         :href="$t('callToActionLink')"
         @click="logCTAClick($t('header.callToAction'))">
        {{ $t('header.callToAction') }}
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
             stroke-width="2" class="w-4 h-4 ml-1 hidden" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </a>
    </div>
      <div class="header-wave fill-current text-background-accent">

        <img src="@/assets/header_bottom_wave.svg" class="w-full"
             alt="transition graphic wave between header and content"/>
      </div>
  </header>
</template>

<script>
import LanguagePicker from '@/components/LanguagePicker.vue';

import eventTracking from '../helpers/eventTracking';

const SECTIONS = [
  '#hero',
  '#team',
];

export default {
  name: 'Nav',
  components: {
    LanguagePicker,
  },
  data() {
    return {
      inView: this.$route.name === 'Home' ? window.location.hash.substr(1) : '',
    };
  },
  methods: {
    pixelsVisibleOnScreen(selector) {
      const c = (window.innerHeight || document.documentElement.clientHeight);
      const boundingRect = document.querySelector(selector).getBoundingClientRect();
      const { y } = boundingRect;
      const h = boundingRect.height;
      if (y + h < 0) return 0;
      if (y < 0) return Math.max(h - y, 0);
      if (y + h <= c) return h;
      return Math.max(c - y, 0);
    },
    determineSelectorInView() {
      const index = SECTIONS.map(this.pixelsVisibleOnScreen)
        .reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
      return SECTIONS[index].substr(1);
    },
    logCTAClick(copy) {
      eventTracking.triggerCallToAction(copy, 'header');
    },
  },
  mounted() {
    document.addEventListener('scroll', () => {
      // Only do the highlighting if on Homepage
      this.inView = this.$route.name === 'Home' ? this.determineSelectorInView() : '';
    }, {
      passive: true,
    });
  },
};
</script>

<style scoped>
.header-wave {
  position: absolute;
  bottom: -1;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: translate(0px,-0.2vh);
}

.header-wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: min(7vw, 7vh);
}

.shadow {
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  /* Similar syntax to box-shadow */
}

.header-link {
  @apply text-background-primary hover:underline
}

.active-header-link {
  @apply text-primary-dark
}
</style>
