export default {
  "header": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["app"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["free Posture Scan"])}
  },
  "hero": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How good"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is your posture?"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower back pain becomes more common than the cold. Wouldn't you want to avoid that?"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free 5-Minute Posture Scan"])}
  },
  "team": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet the Team"])}
  },
  "footer": {
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprint"])},
    "dataPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data privacy"])},
    "dataCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data collection"])}
  },
  "callToActionLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://poselytics.com/form"])},
  "cookieBanner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are using 3rd-party cookies 🍪"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To deliver and continuously improve our services, we are using third party products and services."])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow recommended"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue without non-essential Cookies"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
  },
  "videoBlock": {
    "videoSrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://player.vimeo.com/video/720254966?muted=0&portrait=1"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More important than you think!"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using cutting-edge technology and medical experts, Poselytics analyzes the way you walk and suggests exercises to help improve your posture - no matter if you're a fitness enthusiast or just looking to improve your overall health."])}
  },
  "form": {
    "loadingText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Video is being uploaded."])},
    "loadingText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please do not close this tab."])},
    "continueButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "answerYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "answerNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "answerConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understood"])},
    "answerDataProcessingConsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I voluntarily consent to the collection and processing of my personal data and have been informed about the right of withdrawal."])},
    "videoSrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://player.vimeo.com/video/720254966?muted=0&portrait=1"])},
    "questions": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you at least 18 years old?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to legal reasons we are unfortunately unable to process data of minors."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60-second explanation video"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your video"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note on evaluation of the video"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently, the evaluation of your video is done exclusively by medically trained personnel, i.e., not yet by the algorithm."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to be informed about the result of the evaluation via e-mail?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to be informed about our project progress and other topics such as back health and fitness exercises via e-mail? (Newsletter)"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which email address should we use to contact you?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By submitting your entry, you consent to the processing and collection of your data in accordance with our Privacy Policy and Data Collection Policy."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can withdraw your consent at any time by sending an informal email to hi(at)poselytics.com. If you would rather not agree, please close this tab."])}
      }
    ],
    "upload": {
      "tooBig1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your video is too big"])},
      "tooBig2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try to lower your resolution and try again."])},
      "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Files. Max 100MB"])}
    },
    "submitted": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's it!"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are awesome!"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Home"])}
    }
  }
}