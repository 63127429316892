<template>
  <CookieBanner v-if="showCookieBanner" class="z-100" @disable="setConsentCookie('disabled')"
                @enable="enable()" />
  <Header class="sticky top-0 z-30" />
  <router-view class="z-30" />
  <Footer class="z-30" />
  <div v-if="cookiesAllowed">
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N2FDFS5" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { useCookies } from 'vue3-cookies';
import CookieBanner from './components/CookieBanner.vue';

const cookieName = 'cookieConsentGranted';
const { cookies } = useCookies();

export default {
  name: 'App',
  components: {
    Footer,
    Header,
    CookieBanner,
  },
  methods: {
    /* eslint-disable */
    googleTagManager(w, d, s, l, i) {
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-N2FDFS5');
    },
    /* eslint-enable */
    enableTracking() {
      this.googleTagManager(window, document, 'script', 'dataLayer', 'GTM-N2FDFS5');
      window.dataLayer.push({ event: 'cookiesAllowed' });
      window.dataLayer.push({
        event: 'new_session',
        source: this.source,
      });
    },
    setConsentCookie(val) {
      cookies.set(cookieName, val);
    },
    enable() {
      this.setConsentCookie('enabled');
      this.enableTracking();
    },
    source() {
      if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
        return 'Google';
      }
      if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
        return 'Bing';
      }
      if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
        return 'Yahoo';
      }
      if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
        return 'Facebook';
      }
      if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
        return 'Twitter';
      }
      if (document.referrer.search('https?://(.*)slack.([^/?]*)') === 0) {
        return 'Slack';
      }
      return 'Other';
    },
  },
  created() {
    // Check if consent cookie has been set and only show dialog if it hasn't.
    const cookie = cookies.get(cookieName);

    if (cookie === 'enabled') {
      this.showCookieBanner = false;
      this.cookiesAllowed = true;
      this.enableTracking();
    } else if (cookie === 'disabled') {
      this.showCookieBanner = false;
    } else {
      this.showCookieBanner = true;
    }
  },
  data: () => ({
    showCookieBanner: true,
    cookiesAllowed: false,
  }),
};
</script>
