<template>
<section class="flex flex-col relative items-center relative pb-10 w-screen bg-background-primary
                ">
  <div class="container px-6 md:px-15 flex relative py-16">
    <div class="text-primary-dark flex flex-col gap-8">
      <h1 class="uppercase text-5xl lg:text-6xl xl:text-7xl font-bold pt-8 leading-normal
                 md:leading-relaxed text-white">
        {{ $t('hero.title1') }}<br>
        {{ $t('hero.title2') }}</h1>
      <h2 class="lg:w-1/3 text-primary-light text-2xl md:text-4xl font-light">
        {{ $t('hero.text') }}</h2>
      <div class="py-5 md:px-8 md:py-3 md:text-2xl font-semibold lg:text-4xl btn-primary px-3
                  text-center text-xl md:w-1/2">
        <a class="py-2"
          :href="$t('callToActionLink')" @click="logCTAClick($t('hero.callToAction'))">
          {{ $t('hero.callToAction') }}
        </a>
      </div>
    </div>
    <div class="absolute lg:right-0 lg:top-0 lg:py-28 invisible py-14 right-0 transform
                 xl:translate-x-0 lg:visible">
      <img src="@/assets/mockup.png"
           alt="Hero image of a woman currently being analyzed by Poselytics">
    </div>
  </div>
</section>
</template>

<script>
import eventTracking from '../helpers/eventTracking';

export default {
  name: 'Hero',
  methods: {
    logCTAClick(copy) {
      eventTracking.triggerCallToAction(copy, 'hero');
    },
  },
};
</script>

<style scoped>
</style>
