<template>
<section class="flex flex-col justify-center m-4 mt-20 md:pb-10 lg:mx-14">
  <div class="text-center pb-24">
    <h3 class="text-6xl font-light text-background-primary">{{ $t('team.title') }}</h3>
  </div>
  <div class="flex flex-col lg:flex-row gap-6 lg:gap-6 md:gap-10 py-5 relative w-full justify-center
              place-content-between relative md:gap-3">
    <div v-for="member in members" :key="member.name"
         class="bg-background-primary lg:w-full text-center rounded-xl shadow-md text-primary-light
                pb-10 mb-16 lg:mb-0 sm:w-2/3 w-full mx-auto lg:mx-0">
      <div class="w-full flex justify-center transform -translate-y-14 h-0 mb-24">
        <img :src="member.image" class="rounded-full h-32">
      </div>
      <h4 class="font-bold text-4xl">
        {{member.name}}
      </h4>
      <span class="font-light text-xl">{{member.job}}</span>
      <div class="flex flex-col xs:flex-row xs:place-content-between md:px-10 pt-8
                  content-center gap-3 px-5 font-light text-primary-light justify-center">
        <a class="cursor-pointer hover:text-primary-light mx-auto xs:mx-0"
           :href="member.linkedin" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="h-10 w-10"
               preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
            <path fill="currentColor" fill-rule="evenodd"
                  d="M1 2.838A1.838 1.838 0 0 1 2.838 1H21.16A1.837 1.837 0 0 1 23 2.838V21.16A1.838
                  1.838 0 0 1 21.161 23H2.838A1.838 1.838 0 0 1 1 21.161V2.838Zm8.708
                  6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634c3.169 0 3.92 1.713 3.92
                  4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8c-1.515 0-2.145 1.089-2.145
                  2.8v5.106H9.708V9.388Zm-5.5 10.403h3.208V9.25H4.208v10.54ZM7.875 5.812a2.063 2.063
                  0 1 1-4.125 0a2.063 2.063 0 0 1 4.125 0Z" clip-rule="evenodd"/></svg>
        </a>
        <a :href="`mailto:${member.email}`"
           class="hover:underline text-lg pt-1">{{member.email}}</a>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'Team',
  data() {
    return {
      members: [
        {
          name: 'Anna Feindler',
          job: 'co-founder & CEO',
          email: 'anna@poselytics.com',
          linkedin: 'https://www.linkedin.com/in/anna-feindler-50a592221/',
          // eslint-disable-next-line global-require
          image: require('@/assets/team/anna.png'),
        },
        {
          name: 'Mauritz Andreae',
          job: 'co-founder & CTO',
          email: 'mauritz@poselytics.com',
          linkedin: 'https://www.linkedin.com/in/mauritz-andreae-4b2a501b8/',
          // eslint-disable-next-line global-require
          image: require('@/assets/team/mauritz.jpeg'),
        },
        {
          name: 'Ferdinand Biere',
          job: 'co-founder & CPO',
          email: 'ferdinand@poselytics.com',
          linkedin: 'https://www.linkedin.com/in/ferdinandbiere/',
          // eslint-disable-next-line global-require
          image: require('@/assets/team/ferdinand.jpg'),
        },
      ],
    };
  },
};
</script>

<style scoped>
.grayscale {
  filter: grayscale(100%);
}
</style>
