const triggerEvent = (eventName, copy, location) => {
  window.dataLayer.push({
    event: eventName,
    location,
    copy,
  });
};

const triggerCallToAction = (copy, location) => {
  triggerEvent('conversion_call_to_action', copy, location);
};

export default { triggerEvent, triggerCallToAction };
