export default {
  "header": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["app"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur kostenlosen Haltungsanalyse"])}
  },
  "hero": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie gut"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist Deine Haltung?"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückenschmerzen sind mittlerweile häufiger als Erkältungen. Hast du da wirklich Bock drauf?"])},
    "callToAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloser 5-Minuten-Haltungsscan"])}
  },
  "team": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Team"])}
  },
  "footer": {
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "dataPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "dataCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenerhebung"])}
  },
  "callToActionLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://poselytics.com/form"])},
  "cookieBanner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite verwendet Cookies 🍪"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Erbringung und ständigen Verbesserung unserer Dienstleistungen nutzen wir Produkte und Dienstleistungen Dritter."])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfohlene Einstellungen verwenden"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur mit notwendigen Cookies fortfahren"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])}
  },
  "videoBlock": {
    "videoSrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.tiktok.com/embed/v2/7113111467276209413?lang=de-DE"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtiger als Du glaubst!"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Hilfe modernster Technologie und medizinischer Experten analysiert Poselytics Deinen Gang und schlägt Übungen zur Verbesserung Deiner Haltung vor - egal, ob Du ein Fitness-Enthusiast bist oder einfach nur Deine allgemeine Gesundheit verbessern willst."])}
  },
  "form": {
    "loadingText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Video lädt gerade hoch."])},
    "loadingText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte schließe das Tab nicht.."])},
    "continueButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "answerYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "answerNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "answerConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstanden"])},
    "answerDataProcessingConsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme der Erhebung und Verarbeitung meiner personenbezogenen Daten freiwillig zu und bin über das Widerrufsrecht informiert worden."])},
    "videoSrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://player.vimeo.com/video/720222334?muted=0&portrait=1"])},
    "questions": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist Du mindestens 18 Jahre alt?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus rechtlichen Gründen können wir im Moment nur Anfragen von Volljährigen akzeptieren."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60-Sekunden-Erklärvideo"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimm jetzt bitte ein Video auf und lade es hoch."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis zur Auswertung"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Auswertung Deines Videos geschieht im Moment noch ausschließlich durch medizinisch-geschultes Fachpersonal, also noch nicht durch den Algorithmus."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest Du über das Ergebnis der Auswertung via E-Mail informiert werden?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest Du über unseren Projektfortschritt und andere Themen wie Rückengesundheit und Fitnessübungen via E-Mail informiert werden? (Newsletter)"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche E-Mail-Adresse sollen wir verwenden?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Deiner Einsendung stimmst Du der Verarbeitung und Erhebung Deiner Daten gemäß unserer Datenschutzerklärung und Einwilligungserklärung für die Datenerhebung zu."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst Deine Einwilligung jederzeit mit einer formlosen E-Mail an hi(at)poselytics.com widerrufen. Wenn Du nicht zustimmen möchtest, schließe bitte dieses Tab."])}
      }
    ],
    "upload": {
      "tooBig1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Datei ist zu groß"])},
      "tooBig2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verkleiner die Auflösung und probiere es nochmal."])},
      "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Videos. Max 100MB"])}
    },
    "submitted": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das war's!"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist großartig!"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])}
    }
  }
}