import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('../views/Imprint.vue'),
  },
  {
    path: '/privacy-data-collection',
    name: 'DataCollection',
    component: () => import('../views/DataCollection.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue'),
  },
  {
    path: '/form',
    name: 'Form',
    component: () => import('../views/Form.vue'),
  },
  {
    path: '/form-end-screen',
    name: 'FormSubmitted',
    component: () => import('../views/FormSubmitted.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line consistent-return
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
  },
});

export default router;
