<template>
<section class="grid grid-rows-2 px-5 lg:gap-14 justify-center lg:grid-rows-1 lg:grid-cols-2">
  <div class="rounded-xl lg:justify-self-end mx-auto lg:mx-0 my-4 md:my-0">
    <!--eslint disable-next-line -->
    <iframe :src="$t('videoBlock.videoSrc')" v-if="true" scrolling="no"
    width="281" height="500" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen title="Poselytics" class="rounded-xl overflow-hidden"></iframe>
  </div>
  <div class="lg:py-5 lg:w-1/2 mx-auto lg:mx-0 px-6 lg:px-0- row-start-1 lg:col-start-2">
    <h2 class="text-background-light font-bold text-3xl pb-9">
        {{ $t('videoBlock.header') }}
    </h2>
    <p class="text-background-primary font-light text-2xl mx-auto md:pb-0 pb-10">
        {{ $t('videoBlock.text') }}
    </p>
  </div>
</section>
</template>
<script>
export default {
  name: 'VideoBlock',
};
</script>
<style scoped>
iframe {
    display:block;
    /* width:100%; */
}
</style>
