<template>
<footer class="relative h-16 md:mt-24">
  <div class="z-auto bg-background-primary pt-10 pb-6 flex justify-center text-primary-light">
    <nav class="flex flex-row">
      <div class="flex flex-col border-r-2 border-primary-light pr-4 text-right
                text-primary-light underline">
        <router-link :to="{name: 'Imprint', hash: '#top'}" class="z-50">
          <a class="hover:underline cursor-pointer"
              @click="logLinkPressEvent('/imprint')">{{ $t('footer.imprint') }}</a>
        </router-link>
        <router-link :to="{name: 'Privacy', hash: '#top'}">
          <a class="hover:underline cursor-pointer"
          @click="logLinkPressEvent('/privacy')">{{ $t('footer.dataPrivacy') }}</a>
        </router-link>
        <router-link :to="{name: 'DataCollection', hash: '#top'}">
          <a class="hover:underline cursor-pointer"
             @click="logLinkPressEvent('/privacy-data-collection')">
             {{ $t('footer.dataCollection') }}</a>
        </router-link>
      </div>
      <div class="flex flex-col pl-4 text-left unselectable">
        <span>© 2022</span>
        <span>Poselytics</span>
      </div>
    </nav>
  </div>
  <div class="footer-wave fill-current text-white z-40">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,
        168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,
        18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"></path>
    </svg>
  </div>
</footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    logLinkPressEvent(link) {
      window.dataLayer.push({ event: 'linkClick', target: link });
    },
  },
};
</script>

<style scoped>
.footer-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.footer-wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: min(7vw, 7vh);
}
</style>
