<template>
  <hero id="hero"></hero>
  <div class="wave"></div>
  <div class="w-screen flex justify-center py-14 lg:hidden">
  </div>
  <video-block class="md:pt-44"></video-block>
  <team id="team"></team>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue';
import Team from '@/components/Team.vue';
import VideoBlock from '../components/VideoBlock.vue';

export default {
  name: 'Home',
  components: {
    Team,
    Hero,
    VideoBlock,
  },
};
</script>
<style>
.wave {
  aspect-ratio: 1444 / 205;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../assets/transition_graphic.svg');
}
</style>
